import { Nav } from 'react-bootstrap';
import { organisationPaths } from 'src/routes/employer/paths/organisation.paths';
import { TabLink } from 'src/ui/shared/components/tabs/TabLink';

export const OrganisationDetailsTabNav = (): React.ReactElement => {
    return (
        <Nav variant="tabs" defaultActiveKey="details">
            <TabLink to={organisationPaths.details} matchPath={organisationPaths.details} exact>
                Details
            </TabLink>
            <TabLink to={organisationPaths.settings} matchPath={organisationPaths.settings}>
                Communication settings
            </TabLink>
        </Nav>
    );
};
