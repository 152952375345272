import { createReducer } from 'typesafe-actions';
import { Setting } from '../../../models/settings/setting.model';
import { EntitySingleState } from '../../../models/store-models/entity-single-state.model';
import * as actions from './employer-setting.actions';
import { singleEntityState } from '../../helpers/initial-state.helper';
import { singleReducerHandler } from '../../reducer-handlers/single.reducer-handler';

export type EmployerSettingState = EntitySingleState<Setting[]>;
const initialState: EmployerSettingState = { ...singleEntityState };

const employerSettingReducer = createReducer(initialState)
    .handleAction(actions.employerSettingsAsync.request, singleReducerHandler.request)
    .handleAction(actions.employerSettingsAsync.success, singleReducerHandler.success)
    .handleAction(actions.employerSettingsAsync.cancel, singleReducerHandler.cancel)
    .handleAction(actions.employerSettingsAsync.failure, singleReducerHandler.failure)
    .handleAction(actions.employerSettingsUpdateAsync.request, singleReducerHandler.updateRequest)
    .handleAction(actions.employerSettingsUpdateAsync.success, singleReducerHandler.success)
    .handleAction(actions.employerSettingsUpdateAsync.failure, singleReducerHandler.updateFailure)
    .handleAction(actions.employerSettingsResetState, () => initialState);

export default employerSettingReducer;
