import { Route } from 'react-router-dom';
import { centralPaths } from 'src/routes/employer/paths/central.paths';
import { eventPaths } from 'src/routes/employer/paths/event.paths';
import { organisationPaths } from 'src/routes/employer/paths/organisation.paths';
import { verificationPaths } from 'src/routes/employer/paths/verification.paths';
import { identityPaths } from 'src/routes/identity/identity.paths';
import { IdentityListPageNav } from 'src/ui/features/authentication/components/identity-list/IdentityListPageNav';
import { CentralPublishTitle } from 'src/ui/features/central/components/publish/CentralPublishTitle';
import { EventAddPageNav } from 'src/ui/features/events/components/nav/EventAddPageNav';
import { EventBookingListPageNav } from 'src/ui/features/events/components/nav/EventBookingListPageNav';
import { EventChangeRequestAddPageNav } from 'src/ui/features/events/components/nav/EventChangeRequestAddPageNav';
import { EventChangeRequestListPageNav } from 'src/ui/features/events/components/nav/EventChangeRequestListPageNav';
import { EventCopyPageNav } from 'src/ui/features/events/components/nav/EventCopyPageNav';
import { EventDetailsPageNav } from 'src/ui/features/events/components/nav/EventDetailsPageNav';
import { EventEditPageNav } from 'src/ui/features/events/components/nav/EventEditPageNav';
import { EventListPageNav } from 'src/ui/features/events/components/nav/EventListPageNav';
import { EventPublishConfirmationPageNav } from 'src/ui/features/events/components/nav/EventPublishConfirmationPageNav';
import { EventPublishPageNav } from 'src/ui/features/events/components/nav/EventPublishPageNav';
import { EventSessionListPageNav } from 'src/ui/features/events/components/nav/EventSessionListPageNav';
import { JobPublishConfirmationPageNav } from 'src/ui/features/jobs/components/nav/JobPublishConfirmationPageNav';
import { VerificationDetailsPageNav } from 'src/ui/features/verifications/components/nav/VerificationDetailsPageNav';
import { VerificationListPageNav } from 'src/ui/features/verifications/components/nav/VerificationListPageNav';
import { contentPaths } from '../../../../../routes/content/content.paths';
import { contactPaths } from '../../../../../routes/employer/paths/contact.paths';
import { formPaths } from '../../../../../routes/employer/paths/form.paths';
import { individualPaths } from '../../../../../routes/employer/paths/individual.paths';
import { jobPaths } from '../../../../../routes/employer/paths/job.paths';
import { settingsPaths } from '../../../../../routes/employer/paths/settings.paths';
import { useAuthentication } from '../../../../features/authentication/hooks/authentication.hook';
import { ContactAddPageNav } from '../../../../features/contacts/components/nav/ContactAddPageNav';
import { ContactDetailsPageNav } from '../../../../features/contacts/components/nav/ContactDetailsPageNav';
import { ContactEditPageNav } from '../../../../features/contacts/components/nav/ContactEditPageNav';
import { ContactListPageNav } from '../../../../features/contacts/components/nav/ContactListPageNav';
import { PrivateContentPageNav } from '../../../../features/content/components/PrivateContentPageNav';
import { FormSubmissionListPageNav } from '../../../../features/forms/components/FormSubmissionListPageNav';
import { IndividualDetailsPageNav } from '../../../../features/individual/components/nav/IndividualDetailsPageNav';
import { IndividualEditPageNav } from '../../../../features/individual/components/nav/IndividualEditPageNav';
import { JobAddPageNav } from '../../../../features/jobs/components/nav/JobAddPageNav';
import { JobChangeRequestNav } from '../../../../features/jobs/components/nav/JobChangeRequestPageNav';
import { JobDetailsPageNav } from '../../../../features/jobs/components/nav/JobDetailsPageNav';
import { JobEditPageNav } from '../../../../features/jobs/components/nav/JobEditPageNav';
import { JobListPageNav } from '../../../../features/jobs/components/nav/JobListPageNav';
import { JobPublishPageNav } from '../../../../features/jobs/components/nav/JobPublishPageNav';
import { OrganisationDetailsPageNav } from '../../../../features/organisation/components/nav/OrganisationDetailsPageNav';
import { OrganisationEditPageNav } from '../../../../features/organisation/components/nav/OrganisationEditPageNav';
import { SettingsPageNav } from '../../../../features/settings/components/nav/SettingsPageNav';
import { OrganisationSettingsPageNav } from 'src/ui/features/organisation/components/nav/OrganisationSettingsPageNav';

export const ApplicationTopNavSwitch = (): React.ReactElement<any, any> | null => {
    const { isOrganisation, isIndividual } = useAuthentication();
    return (
        <>
            <Route path={jobPaths.publish} exact={true} component={JobPublishPageNav} />
            <Route
                path={jobPaths.publishConfirmation}
                exact={true}
                component={JobPublishConfirmationPageNav}
            />
            <Route path={jobPaths.edit} exact={true} component={JobEditPageNav} />
            <Route path={jobPaths.changeRequest} exact={true} component={JobChangeRequestNav} />
            <Route path={jobPaths.details} exact={true} component={JobDetailsPageNav} />
            <Route path={jobPaths.add} exact={true} component={JobAddPageNav} />
            <Route path={jobPaths.list} exact={true} component={JobListPageNav} />
            <Route path={settingsPaths.settings} exact={true} component={SettingsPageNav} />
            <Route
                path={verificationPaths.details}
                exact={true}
                component={VerificationDetailsPageNav}
            />
            <Route path={verificationPaths.list} exact={true} component={VerificationListPageNav} />
            <Route
                path={formPaths.submissions}
                exact={true}
                component={FormSubmissionListPageNav}
            />
            <Route path={contentPaths.protected} exact={true} component={PrivateContentPageNav} />
            <Route
                path={identityPaths.listAuthenticated}
                exact={true}
                component={IdentityListPageNav}
            />

            {isIndividual && (
                <>
                    <Route
                        path={individualPaths.edit}
                        exact={true}
                        component={IndividualEditPageNav}
                    />
                    <Route
                        path={individualPaths.details}
                        exact={true}
                        component={IndividualDetailsPageNav}
                    />
                </>
            )}
            {isOrganisation && (
                <>
                    <Route
                        path={organisationPaths.edit}
                        exact={true}
                        component={OrganisationEditPageNav}
                    />
                    <Route
                        path={organisationPaths.settings}
                        exact={true}
                        component={OrganisationSettingsPageNav}
                    />
                    <Route
                        path={organisationPaths.details}
                        exact={true}
                        component={OrganisationDetailsPageNav}
                    />

                    <Route path={contactPaths.edit} exact={true} component={ContactEditPageNav} />
                    <Route
                        path={contactPaths.details}
                        exact={true}
                        component={ContactDetailsPageNav}
                    />
                    <Route path={contactPaths.add} exact={true} component={ContactAddPageNav} />
                    <Route path={contactPaths.list} exact={true} component={ContactListPageNav} />
                    <Route
                        path={centralPaths.publish}
                        exact={true}
                        component={CentralPublishTitle}
                    />

                    <Route
                        path={eventPaths.bookingList}
                        exact={true}
                        component={EventBookingListPageNav}
                    />
                    <Route path={eventPaths.edit} exact={true} component={EventEditPageNav} />
                    <Route path={eventPaths.publish} exact={true} component={EventPublishPageNav} />
                    <Route
                        path={eventPaths.publishConfirmation}
                        exact={true}
                        component={EventPublishConfirmationPageNav}
                    />
                    <Route
                        path={eventPaths.changeRequestList}
                        exact={true}
                        component={EventChangeRequestListPageNav}
                    />
                    <Route
                        path={eventPaths.changeRequestAdd}
                        exact={true}
                        component={EventChangeRequestAddPageNav}
                    />
                    <Route path={eventPaths.copy} exact={true} component={EventCopyPageNav} />
                    <Route path={eventPaths.details} exact={true} component={EventDetailsPageNav} />
                    <Route path={eventPaths.add} exact={true} component={EventAddPageNav} />
                    <Route path={eventPaths.list} exact={true} component={EventListPageNav} />
                    <Route
                        path={[eventPaths.sessionList, eventPaths.sessionCheckIn]}
                        exact={true}
                        component={EventSessionListPageNav}
                    />
                </>
            )}
        </>
    );
};
