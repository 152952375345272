import { Route, Switch } from 'react-router-dom';
import { OrganisationSettingsPage } from 'src/ui/features/organisation/pages/OrganisationSettingsPage';
import { OrganisationDetailsPage } from '../../ui/features/organisation/pages/OrganisationDetailsPage';
import { OrganisationEditPage } from '../../ui/features/organisation/pages/OrganisationEditPage';
import { organisationPaths } from './paths/organisation.paths';

export const OrganisationRouter = () => {
    const { edit, details, settings } = organisationPaths;

    return (
        <Switch>
            <Route path={edit} component={OrganisationEditPage} />
            <Route path={settings} component={OrganisationSettingsPage} />
            <Route path={details} component={OrganisationDetailsPage} />
        </Switch>
    );
};
