import { formatISO } from 'date-fns';
import uniqueId from 'lodash/uniqueId';
import { contactPathCreator } from 'src/routes/employer/paths/contact.paths';
import { organisationPaths } from 'src/routes/employer/paths/organisation.paths';
import { createReducer } from 'typesafe-actions';
import { EntityAction, ToastTargetType } from '../../../constants/entity.constants';
import { ToastModel } from '../../../models/session/toast.model';
import { UserHistory } from '../../../models/session/user-history.model';
import { individualPaths } from '../../../routes/employer/paths/individual.paths';
import { jobPathCreator } from '../../../routes/employer/paths/job.paths';
import { settingsPaths } from '../../../routes/employer/paths/settings.paths';
import actions from '../action.root-index';
import { sessionReset, sessionShowHistory, sessionToastDelete } from './session.actions';

export type SessionState = Readonly<{
    history: UserHistory[];
    toasts: ToastModel[];
    showHistory: boolean;
}>;
const initialState: SessionState = {
    toasts: [],
    history: [],
    showHistory: false,
};

const createToast: (
    toast: Partial<Omit<ToastModel, 'id'>> & { action: string; type: ToastTargetType }
) => ToastModel = toast => ({
    id: uniqueId(),
    variant: 'success',
    autoHide: true,
    ...toast,
});

const success: (options: {
    type: ToastTargetType;
    action: EntityAction;
    to: string;
}) => (state: SessionState) => SessionState =
    ({ type, to, action }) =>
    state => ({
        ...state,
        history: [
            {
                id: uniqueId(),
                action,
                date: formatISO(new Date()),
                to,
                type,
            },
            ...state.history,
        ],
        toasts: [...state.toasts, createToast({ action, type })],
    });

const sessionReducer = createReducer(initialState)
    .handleAction(actions.contacts.contactCreateAsync.success, (state, action) =>
        success({
            type: 'contact',
            action: 'create',
            to: contactPathCreator.details(action.payload.data.id),
        })(state)
    )
    .handleAction(actions.contacts.contactDeleteAsync.success, (state, action) =>
        success({
            action: 'delete',
            type: 'contact',
            to: contactPathCreator.details(action.payload.data.id),
        })(state)
    )
    .handleAction(actions.contacts.contactSetPrimaryAsync.success, (state, action) =>
        success({
            action: 'update',
            type: 'contact',
            to: contactPathCreator.details(action.payload.data.id),
        })(state)
    )
    .handleAction(actions.contacts.contactUpdateAsync.success, (state, action) =>
        success({
            action: 'update',
            type: 'contact',
            to: contactPathCreator.details(action.payload.data.id),
        })(state)
    )
    .handleAction(actions.individual.individualUpdateAsync.success, (state, action) =>
        success({
            action: 'update',
            type: 'individual',
            to: individualPaths.details,
        })(state)
    )
    .handleAction(actions.jobs.jobChangeRequestAsync.success, (state, action) =>
        success({
            action: 'create',
            type: 'changeRequest',
            to: jobPathCreator.details(action.payload.data.id),
        })(state)
    )
    .handleAction(actions.jobs.jobCreateAsync.success, (state, action) =>
        success({
            action: 'create',
            type: 'job',
            to: jobPathCreator.details(action.payload.data.id),
        })(state)
    )
    .handleAction(actions.jobs.jobDeleteAsync.success, (state, action) =>
        success({
            action: 'delete',
            type: 'job',
            to: jobPathCreator.details(action.payload.data.id),
        })(state)
    )
    .handleAction(actions.jobs.jobPublishAsync.success, (state, action) =>
        success({
            action: 'publish',
            type: 'job',
            to: jobPathCreator.details(action.payload.data.id),
        })(state)
    )
    .handleAction(actions.jobs.jobUpdateAsync.success, (state, action) =>
        success({
            action: 'update',
            type: 'job',
            to: jobPathCreator.details(action.payload.data.id),
        })(state)
    )
    .handleAction(actions.jobs.jobWithdrawAsync.success, (state, action) =>
        success({
            action: 'withdraw',
            type: 'job',
            to: jobPathCreator.details(action.payload.data.id),
        })(state)
    )
    .handleAction(actions.organisation.organisationUpdateAsync.success, (state, action) =>
        success({
            action: 'update',
            type: 'organisation',
            to: organisationPaths.details,
        })(state)
    )
    .handleAction(actions.settings.settingsUpdateAsync.success, (state, action) =>
        success({
            action: 'update',
            type: 'settings',
            to: settingsPaths.settings,
        })(state)
    )
    .handleAction(actions.employerSettings.employerSettingsUpdateAsync.success, (state, action) =>
        success({
            action: 'update',
            type: 'organisationSetting',
            to: organisationPaths.settings,
        })(state)
    )

    // unique session actions
    .handleAction(sessionToastDelete, (state, action) => ({
        ...state,
        toasts: state.toasts.filter(i => i.id !== action.payload.id),
    }))
    .handleAction(sessionShowHistory, (state, action) => ({
        ...state,
        toasts: action.payload.showHistory ? [] : state.toasts,
        showHistory: action.payload.showHistory,
    }))
    .handleAction(sessionReset, () => initialState);

export default sessionReducer;
