import { LOCATION_CHANGE } from 'connected-react-router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { delayTypeUntilAuthenticated } from 'src/logic/helpers/epics/app-init.epic-helper';
import { organisationPaths } from 'src/routes/employer/paths/organisation.paths';
import { asyncEpicStandard } from '../../helpers/epics/async.epic-helper';
import { RootEpic } from '../epic.root-index';
import { employerSettingsAsync, employerSettingsUpdateAsync } from './employer-setting.actions';

export const employerSettingRouteEpic: RootEpic = (action$, state$) => {
    return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
        filter(action => action.payload.location.pathname === organisationPaths.settings),
        filter(() => !state$.value.employerSettings.value),
        mergeMap(() => of(employerSettingsAsync.request()))
    );
};

export const employerSettingListEpic = asyncEpicStandard(employerSettingsAsync, ({ api }) =>
    api.employerSettings.list()
);
export const employerSettingUpdateEpic = asyncEpicStandard(
    employerSettingsUpdateAsync,
    ({ api }, { payload }) => api.employerSettings.update(payload)
);
