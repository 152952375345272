import { OrganisationDetails } from '../components/details/OrganisationDetails';
import { OrganisationDetailsPageContent } from '../components/details/OrganisationDetailsPageContent';
import { useOrganisation } from '../hooks/organisation.hook';

export const OrganisationDetailsPage = (): React.ReactElement => {
    const { organisation } = useOrganisation();

    return (
        <OrganisationDetailsPageContent>
            {organisation && <OrganisationDetails organisation={organisation} />}
        </OrganisationDetailsPageContent>
    );
};
