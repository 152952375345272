import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { useOrganisation } from '../../hooks/organisation.hook';
import { OrganisationDetailsTabNav } from '../nav/OrganisationDetailsTabNav';
import { useIsCareerHubVersion } from 'src/ui/shared/hooks/is-careerhub-version.hook';

interface Props {
    children: React.ReactNode;
}

export const OrganisationDetailsPageContent = ({ children }: Props): React.ReactElement => {
    const { organisation, fetch } = useOrganisation();
    const v5_14 = useIsCareerHubVersion(5, 14);

    return (
        <ApplicationPageContent className="details-page">
            <Loader state={fetch} useCard={true}>
                {organisation && !v5_14 && <CardWrapper>{children}</CardWrapper>}
                {organisation && v5_14 && (
                    <div className="tab-wrapper">
                        <OrganisationDetailsTabNav />
                        <CardWrapper>
                            <>{children}</>
                        </CardWrapper>
                    </div>
                )}
            </Loader>
        </ApplicationPageContent>
    );
};
