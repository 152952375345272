import { Loader } from 'src/ui/shared/components/loader/Loader';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { SettingsForm, SettingsFormValue } from '../../settings/components/forms/SettingsForm';
import { OrganisationDetailsPageContent } from '../components/details/OrganisationDetailsPageContent';
import { useDispatch } from 'react-redux';
import { employerSettingsUpdateAsync } from 'src/logic/features/employer-settings/employer-setting.actions';
import { Setting } from 'src/models/settings/setting.model';

export const OrganisationSettingsPage = (): React.ReactElement => {
    const dispatch = useDispatch();
    const {
        request: { fetch, update },
        value,
    } = useRootSelector(state => state.employerSettings);

    const onSubmit = (data: SettingsFormValue) => {
        const toSubmit: Setting[] = value!.map(i => ({
            ...i,
            subscribed: !!data[i.name],
        }));
        dispatch(employerSettingsUpdateAsync.request(toSubmit));
    };

    return (
        <OrganisationDetailsPageContent>
            <Loader state={fetch}>
                {value && <SettingsForm settings={value} onSubmit={onSubmit} state={update} />}
            </Loader>
        </OrganisationDetailsPageContent>
    );
};
