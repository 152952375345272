import * as icons from '@fortawesome/free-solid-svg-icons';
import { IconDefinitionAll } from '../ui/shared/components/icon/Icon';

export type ToastTargetType =
    | 'job'
    | 'contact'
    | 'organisation'
    | 'settings'
    | 'individual'
    | 'changeRequest'
    | 'organisationSetting';
export type EntityAction = 'create' | 'update' | 'delete' | 'withdraw' | 'publish';

interface IconTypeMeta {
    icon: IconDefinitionAll;
}

export const ICON_TYPE_META: { [key in ToastTargetType]: IconTypeMeta } = {
    job: { icon: icons.faBriefcase },
    contact: { icon: icons.faUserTie },
    organisation: { icon: icons.faBuilding },
    individual: { icon: icons.faUser },
    settings: { icon: icons.faEnvelope },
    changeRequest: { icon: icons.faBriefcase },
    organisationSetting: { icon: icons.faEnvelope },
};
