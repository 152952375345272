import { LocationChangeAction, routerActions } from 'connected-react-router';
import { ActionType } from 'typesafe-actions';
import * as analytics from './analytics/analytics.actions';
import * as categories from './api-configuration/api-configuration.actions';
import * as attachments from './attachments/attachment.actions';
import * as authentication from './authentication/authentication.actions';
import * as central from './central/central.actions';
import * as configuration from './configuration/configuration.actions';
import * as contacts from './contacts/contact.actions';
import * as content from './content/content.actions';
import * as emailClaims from './email-claims/email-claims.actions';
import * as eventUnions from './event-unions/event-union.actions';
import * as formSubmissions from './form-submissions/form-submission.actions';
import * as individual from './individuals/individual.actions';
import * as init from './initialization/initialization.actions';
import * as jobs from './jobs/job.actions';
import * as organisation from './organisations/organisation.actions';
import * as registration from './register/register.actions';
import * as toasts from './sessions/session.actions';
import * as settings from './settings/setting.actions';
import * as employerSettings from './employer-settings/employer-setting.actions';
import * as strings from './strings/string.actions';
import * as workGroups from './work-groups/work-group.actions';
import * as eventBookings from './event-bookings/event-booking.actions';
import * as entityNotes from './entity-notes/entity-note.actions';
import * as uploads from './uploads/upload.actions';
import * as skills from './skills/skill.actions';
import * as endorsements from './endorsements/endorsement.actions';
import * as verifications from './verifications/verification.actions';
import * as options from './options/option.actions';

const rootActions = {
    router: routerActions,
    init,
    authentication,
    jobs,
    organisation,
    individual,
    contacts,
    registration,
    eventUnions,
    eventBookings,
    settings,
    employerSettings,
    categories,
    content,
    attachments,
    formSubmissions,
    toasts,
    central,
    strings,
    configuration,
    emailClaims,
    workGroups,
    entityNotes,
    skills,
    endorsements,
    verifications,
    options,
    uploads,
    analytics,
};

export type RootAction = ActionType<typeof rootActions> | LocationChangeAction;
export default rootActions;
