import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { settingsUpdateAsync } from 'src/logic/features/settings/setting.actions';
import { Setting } from 'src/models/settings/setting.model';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { AlertWrapper } from '../../../shared/components/bootstrap/alerts/AlertWrapper';
import { CardWrapper } from '../../../shared/components/bootstrap/cards/CardWrapper';
import { Loader } from '../../../shared/components/loader/Loader';
import { ApplicationPageContent } from '../../../shared/components/page-components/ApplicationPageContent';
import { SettingsForm, SettingsFormValue } from '../components/forms/SettingsForm';

export const SettingsPage = (): React.ReactElement => {
    const dispatch = useDispatch();

    const {
        request: { fetch, update },
        value,
    } = useRootSelector(state => state.settings);

    const onSubmit = (data: SettingsFormValue) => {
        const toSubmit: Setting[] = value!.map(i => ({
            ...i,
            subscribed: !!data[i.name],
        }));

        dispatch(settingsUpdateAsync.request(toSubmit));
    };

    return (
        <ApplicationPageContent>
            <CardWrapper>
                <Loader state={fetch}>
                    <AlertWrapper variant="info" icon={faInfoCircle}>
                        System and automatic emails cannot be unsubscribed
                    </AlertWrapper>
                    {value && <SettingsForm settings={value} onSubmit={onSubmit} state={update} />}
                </Loader>
            </CardWrapper>
        </ApplicationPageContent>
    );
};
