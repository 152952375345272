import { useMemo } from 'react';
import { EntityAction, ToastTargetType } from 'src/constants/entity.constants';
import { stringHelper } from 'src/ui/shared/helpers/string.helper';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const useSessionText = (type: ToastTargetType, action: EntityAction) => {
    const { contact, organisation, individual, job } = useRootSelector(
        state => state.strings.careerHubStrings
    );

    const entityString = useMemo(() => {
        switch (type) {
            case 'job':
                return job;
            case 'organisation':
                return organisation;
            case 'contact':
                return contact;
            case 'individual':
                return individual;
            case 'changeRequest':
                return 'change request';
            case 'settings':
                return 'setting';
            case 'organisationSetting':
                return `${organisation} setting`;
        }
    }, [contact, individual, job, organisation, type]);

    const text = useMemo(() => {
        switch (action) {
            case 'create':
                return `${stringHelper.toTitleCase(entityString)} created`;
            case 'delete':
                return `${stringHelper.toTitleCase(entityString)} deleted`;
            case 'publish':
                return `${stringHelper.toTitleCase(entityString)} published`;
            case 'withdraw':
                return `${stringHelper.toTitleCase(entityString)} withdrawn`;
            case 'update':
                return `${stringHelper.toTitleCase(entityString)} updated`;
        }
    }, [entityString, action]);

    return text;
};
