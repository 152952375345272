import { createAction, createAsyncAction } from 'typesafe-actions';
import { ApiResponse } from '../../../models/api/response.model';
import { ErrorNormalized } from '../../../models/errors/error.model';
import { Setting } from '../../../models/settings/setting.model';

export const employerSettingsResetState = createAction('EMPLOYER_SETTINGS_RESET_STATE')();

export const employerSettingsAsync = createAsyncAction(
    'EMPLOYER_SETTINGS:REQUEST',
    'EMPLOYER_SETTINGS:SUCCESS',
    'EMPLOYER_SETTINGS:FAILURE',
    'EMPLOYER_SETTINGS:CANCEL'
)<undefined, ApiResponse<Setting[]>, ErrorNormalized, undefined>();

export const employerSettingsUpdateAsync = createAsyncAction(
    'EMPLOYER_SETTINGS_UPDATE:REQUEST',
    'EMPLOYER_SETTINGS_UPDATE:SUCCESS',
    'EMPLOYER_SETTINGS_UPDATE:FAILURE'
)<Setting[], ApiResponse<Setting[]>, ErrorNormalized>();
