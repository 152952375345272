import { LoaderState } from 'src/models/errors/error.model';
import { Setting } from '../../../../../models/settings/setting.model';
import { HookForm } from '../../../../shared/components/forms/HookForm';
import { useFormDefault } from '../../../../shared/hooks/form-default.hook';

interface Props {
    settings: Setting[];
    onSubmit: (value: SettingsFormValue) => void;
    state: LoaderState;
}

export interface SettingsFormValue {
    [name: string]: boolean;
}

export const SettingsForm = ({ settings, onSubmit, state }: Props): React.ReactElement => {
    const defaultValues: SettingsFormValue = {};
    settings.forEach(setting => (defaultValues[setting.name.toString()] = !!setting.subscribed));

    const formMethods = useFormDefault<SettingsFormValue>({
        defaultValues,
        error: state.error,
    });

    return (
        <HookForm {...formMethods} onSubmit={onSubmit}>
            <HookForm.ErrorAlert error={state.error} />
            {settings.map(setting => (
                <div key={setting.id || setting.name}>
                    <HookForm.Checkbox label={setting.name} name={setting.name.toString()} />
                </div>
            ))}
            <HookForm.Buttons showSpinner={state.loading} />
        </HookForm>
    );
};
